












import Vue from 'vue';
import ToastMessage from '@/models/ToastMessage';
import AuthService from '@/services/AuthService';

export default Vue.extend({
  data() {
    return {
      loading: true,
      message: '',
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    newEmail: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkErrors(errors: Array<string>): boolean {
      return errors.length == 0;
    },

    loadData(): void {
      this.loading = true;
      this.message = '';
      AuthService.confirmChangeEmail(this.userId, this.token, this.newEmail).then(
        () => {
          this.$store.commit('addToastMessage', new ToastMessage('E-Mail erfolgreich geändert', 'bg-success'));

          this.$store.dispatch('AuthStoreModule/logout').then(() => {
            this.$router.push('Login');
          });
        },
        (error) => {
          this.loading = false;
          this.message = error;
          this.$store.commit('addToastMessage', new ToastMessage('Fehlgeschlagen', 'bg-warning'));
        }
      );
    },
  },
  mounted() {
    this.loadData();
  },
});
